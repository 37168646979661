<template>
  <b-nav-item-dropdown
    class="dropdown-notification"
    menu-class="dropdown-menu-media tw-w-full notebook md:tw-w-[400px] tw-max-h-[400px] py-0"
    no-caret
    right
    toggle-class="p-0"
  >
    <template #button-content>
      <li v-tippy="{content: 'Notebook'}" class="nav-item nav-item-custom">
        <div
          :class="todoList.length > 0 ? 'text-primary' : 'text-body'"
          class="nav-link rounded-lg d-flex align-items-center"
        >
          <font-ai :icon="['fas', 'book']" class="font-medium-1" />
        </div>
      </li>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header border-bottom-0 py-50">
      <div class="dropdown-header px-2 tw-flex tw-justify-between tw-items-center">
        <h4 class="tw-text-xl tw-mb-0 tw-font-bold tw-text-gray-700">Notebook</h4>
        <span class="board-badge tw-bg-sky-50 tw-text-sky-600 tw-ring-sky-700/10"> {{ todoList.length }} Items</span>
      </div>
    </li>

    <TaskToDo :todo-list="todoList" @update="updateTodoList" @clear-list="todoList = []" />
  </b-nav-item-dropdown>
</template>

<script>
import {BNavItemDropdown} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import TaskToDo from '@/components/Task/TaskToDo.vue';

export default {
  components: {
    TaskToDo,
    BNavItemDropdown,
  },
  directives: {
    Ripple,
  },
  created() {
    // console.log(JSON.parse(localStorage.getItem('notebook-list')))
    if (localStorage.getItem('notebook-list') !== null) {
      this.todoList = JSON.parse(localStorage.getItem('notebook-list'));
    }
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      todoList: [],
    };
  },
  methods: {
    updateTodoList(e) {
      localStorage.setItem('notebook-list', JSON.stringify(e));
    },
  },
};
</script>

<style>
.dropdown-notification {
  list-style-type: none;
}

.header-navbar .navbar-container .dropdown-menu-media {
  overflow: hidden;
}

.notebook {
  @media (max-width: 768px) {
    border-radius: 0;
    position: fixed !important;
    top: 35px !important;
    left: 0 !important;
    transform: translate3d(0, 0, 0) !important;
  }
}
</style>