<template>
  <b-nav-item-dropdown
    class="dropdown-notification border-0"
    menu-class="dropdown-menu-media py-0 currencies-menu-media tw-w-full"
    no-caret
    right
    toggle-class="p-0"
    @show="getCurrencies"
  >
    <template #button-content>
      <li v-tippy="{content: 'Currencies'}" class="nav-item nav-item-custom">
        <div class="nav-link rounded-lg d-flex align-items-center text-body">
          <font-ai :icon="['fas', 'coins']" class="font-medium-1" />
        </div>
      </li>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header border-bottom-0 pt-50">
      <div class="dropdown-header px-2 d-flex">
        <h4 class="notification-title mb-0 mr-auto">Currencies</h4>
      </div>
    </li>

    <div>
      <div class="px-1 pb-1 pt-25">
        <b-input-group class="light-form-element">
          <b-form-input
            v-model="currentCurrency"
            placeholder="Add currency value and press Enter"
            @input="onUpdateCurrencies"
            @keydown.enter="onUpdateCurrencies"
          />
          <b-input-group-append>
            <b-form-select
              v-model="selectedCurrency"
              :options="currenciesArr"
              class="border-0 bg-light"
              style="border-radius: 0 10px 10px 0 !important"
              text-field="code"
            />
          </b-input-group-append>
        </b-input-group>
      </div>
      <!--      <p class="mb-0 mt-1" v-for="currency in currencies" :key="currency.code">-->
      <!--        <span class="font-weight-bold">{{ currency.code }}</span-->
      <!--        >: {{ currency.value * Number(currentCurrency) }}-->
      <!--      </p>-->
      <DataTable :value="currencies" class="p-datatable mb-0" dataKey="id" responsiveLayout="scroll">
        <Column :body-style="{color: '#6e6b7b'}" body-class="pl-1" field="code" header="Code" header-class="pl-1">
          <template #body="slotProps">
            <font-ai
              v-if="['CAD', 'USD'].includes(slotProps.data.code)"
              :icon="['fas', 'dollar-sign']"
              :style="{'min-width': '15px'}"
              class="font-small-3"
            />
            <font-ai
              v-else-if="['RUB'].includes(slotProps.data.code)"
              :icon="['fas', 'ruble-sign']"
              :style="{'min-width': '15px'}"
              class="font-small-3"
            />
            <font-ai
              v-else-if="['TRY'].includes(slotProps.data.code)"
              :icon="['fas', 'turkish-lira']"
              :style="{'min-width': '15px'}"
              class="font-small-3"
            />
            <font-ai
              v-else-if="['EUR'].includes(slotProps.data.code)"
              :icon="['fas', 'euro-sign']"
              :style="{'min-width': '15px'}"
              class="font-small-3"
            />
            <font-ai v-else :icon="['fas', 'coins']" :style="{'min-width': '15px'}" class="font-small-2" />
            {{ slotProps.data.text }}
          </template>
        </Column>
        <Column :body-style="{color: '#6e6b7b'}" body-class="">
          <template #header="slotProps">{{ `Currency (1 ${selectedCurrency})` }}</template>
          <template #body="slotProps">
            {{ `${Number(slotProps.data.value).toFixed(4)}` }}
          </template>
        </Column>
        <Column header="Total">
          <template #body="slotProps">
            {{ Number(Number(currentCurrency) * slotProps.data.value).toFixed(2) }}
          </template>
        </Column>
      </DataTable>
      <div class="p-1 d-flex flex-column font-small-3 text-muted">
        <span v-if="lastUpdatedAt !== ''">Last updated at: {{ formatDate }}</span>
      </div>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, BNavItemDropdown} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';
import Column from 'primevue/column/Column';
import DataTable from 'primevue/datatable/DataTable';
import 'primevue/resources/themes/saga-blue/theme.css';
import moment from 'moment';

export default {
  components: {
    DataTable,
    Column,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BNavItemDropdown,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentCurrency: '',
      currencies: [],
      currenciesArr: [
        {
          value: 'EUR',
          code: 'EUR',
          text: 'Euro',
        },
        {
          value: 'USD',
          code: 'USD',
          text: 'Dollar',
        },
        {
          value: 'TRY',
          code: 'TRY',
          text: 'Turkish lira',
        },
        {
          value: 'CAD',
          code: 'CAD',
          text: 'Canadian dollar',
        },
        {
          value: 'RUB',
          code: 'RUB',
          text: 'Russian ruble',
        },
        {
          value: 'RSD',
          code: 'RSD',
          text: 'Serbian dinar',
        },
        {
          value: 'PHP',
          code: 'PHP',
          text: 'Philippine peso',
        },
      ],
      selectedCurrency: 'RUB',
      lastUpdatedAt: '',
      timer: null,
    };
  },
  computed: {
    formatDate() {
      return moment(this.lastUpdatedAt).format('DD.MM.YYYY');
    },
  },
  watch: {
    selectedCurrency() {
      this.getCurrencies();
    },
  },
  methods: {
    getCurrencies() {
      this.currencies = [];
      this.currenciesArr.forEach(el => {
        axios
          .get(
            `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${this.selectedCurrency.toLowerCase()}/${el.code.toLowerCase()}.min.json`
          )
          .then(r => {
            this.lastUpdatedAt = r.data.date;
            if (el.code !== this.selectedCurrency) {
              this.currencies.push({
                code: el.code,
                value: r.data[el.code.toLowerCase()],
                text: el.text,
              });
            }
          });
      });
    },
    onUpdateCurrencies() {
      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(() => {
        this.getCurrencies();
      }, 500);
    },
  },
};
</script>

<style>
.p-component {
  font-family: 'Montserrat', serif;

  .p-datatable-thead > tr > th,
  .p-datatable-tbody > th {
    color: unset;
  }
}

.dropdown-notification {
  list-style-type: none;
}

.header-navbar .navbar-container .dropdown-menu-media {
  overflow: hidden;
}

.currencies-menu-media {
  width: 35rem !important;

  @media (max-width: 768px) {
    width: 100% !important;
    border-radius: 0;
    position: fixed !important;
    top: 35px !important;
    left: 0 !important;
    transform: translate3d(0, 0, 0) !important;
  }
}
</style>