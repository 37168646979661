<template>
  <div class="d-flex">
    <div v-if="$route.name === 'board'" class="tw-flex tw-items-center">
      <button
        class="tw-px-3 tw-py-1 tw-bg-slate-100 tw-rounded-s-lg tw-border-s tw-border-y"
        :class="{'tw-bg-slate-200': itemView === 'grid-view'}"
        @click="itemView = 'grid-view'"
      >
        <svg
          :class="{'tw-text-sky-600': itemView === 'grid-view'}"
          class="tw-w-6 tw-h-6"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 4.5v15m6-15v15m-10.875 0h15.75c.621 0 1.125-.504 1.125-1.125V5.625c0-.621-.504-1.125-1.125-1.125H4.125C3.504 4.5 3 5.004 3 5.625v12.75c0 .621.504 1.125 1.125 1.125z"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <button
        class="tw-px-3 tw-py-1 tw-bg-slate-100 tw-rounded-e-lg tw-border-e tw-border-y"
        :class="{'tw-bg-slate-200': itemView === 'list-view'}"
        @click="itemView = 'list-view'"
      >
        <svg
          :class="{'tw-text-sky-600': itemView === 'list-view'}"
          class="tw-w-6 tw-h-6"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <!--    <b-form-radio-group-->
    <!--      v-if="$route.name === 'board'"-->
    <!--      v-model="itemView"-->
    <!--      button-variant="outline-primary"-->
    <!--      buttons-->
    <!--      class="list item-view-radio-group"-->
    <!--      size="sm"-->
    <!--    >-->
    <!--      <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">-->
    <!--        <feather-icon :icon="option.icon" size="18" />-->
    <!--      </b-form-radio>-->
    <!--    </b-form-radio-group>-->
  </div>
</template>

<script>
import store from '@/store'

export default {
  data() {
    return {
      itemViewOptions: [
        {icon: 'GridIcon', value: 'grid-view'},
        {icon: 'ListIcon', value: 'list-view'},
      ],
    }
  },
  computed: {
    isProject() {
      let project = this.$store.state.osBoard.selectedProject
      return project !== null ? project.title !== '' || this.$store.state.board.isMyTasks : null
    },
    itemView: {
      get: () => store.state.board.itemView,
      set: val => store.commit('board/CHANGE_VIEW', val),
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-button {
  color: #b4b4b4;
  cursor: pointer;
  outline: 0;

  &:hover {
    color: #818181;
  }

  &.filtered {
    color: #0095e8;

    &:hover {
      color: #007bffff;
    }
  }

  &:not(.filtered) {
    border-color: #b4b4b4 !important;
  }
}
</style>