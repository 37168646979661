<template>
  <li v-tippy="{content: 'Filter'}" class="nav-item nav-item-custom">
    <b-link
      class="nav-link rounded-lg d-flex align-items-center"
      @click="() => $store.commit('other/changeViewFilter', true)"
    >
      <font-ai :icon="['fas', 'filter']" class="font-medium-1" :class="isProject ? 'text-primary' : 'text-body'" />
    </b-link>
  </li>
</template>

<script>
import {
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
import SprintsService from '@/services/sprints.service'

export default {
  components: {
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sprints: [],
    }
  },
  computed: {
    isProject() {
      let project = this.$store.state.osBoard.selectedProject
      return project !== null ? project.title !== '' || this.$store.state.board.isMyTasks : null
    },
    filter: {
      get: () => store.state.other.showFilter,
      set: val => {
        store.commit('other/changeViewFilter', val)
      },
    },
    isImPerformer: {
      get: () => store.state.board.isImPerformer,
      set: val => {
        localStorage.setItem('is_im_performer', JSON.stringify(val))
        store.commit('board/CHANGE_STATUS_IM_PERFORMER', val)
      },
    },
    isMyTasks: {
      get: () => store.state.board.isMyTasks,
      set: val => {
        localStorage.setItem('is_my_tasks', JSON.stringify(val))
        store.commit('board/CHANGE_STATUS_MY_TASKS', val)
      },
    },
    project: {
      get: () => store.state.osBoard.selectedProject,
      set: val => {
        localStorage.setItem('selected_project', JSON.stringify(val))
        store.commit('osBoard/SET_PROJECT', val)
      },
    },
    sprint: {
      get: () => store.state.osBoard.selectedSprint,
      set: val => {
        localStorage.setItem('selected_filter_sprint', JSON.stringify(val))
        store.commit('osBoard/SET_SPRINT', val)
      },
    },
    selectProjects: {
      get: () => store.state.osBoard.selectedProjects,
      set: val => {
        localStorage.setItem('selected_projects', JSON.stringify(val))
        store.commit('osBoard/SET_PROJECTS', val)
      },
    },
    projects() {
      return store.state.osBoard.projects
    },
  },
  watch: {
    project() {
      if (router.history.current.path !== '/login') this.getSprintsForProject()
      this.sprint = {
        goal: '',
        name: '',
      }
    },
  },
  methods: {
    clearFilter() {
      this.$store.commit('osBoard/CLEAR_PROJECT')
      this.$store.commit('board/CHANGE_STATUS_MY_TASKS', false)
      this.$store.commit('board/GET_STATUSES', [])
      this.sprint = {
        goal: '',
        name: '',
      }
    },
    getSprintsForProject() {
      // if (localStorage.getItem('access_token')) {
      //   SprintsService.getSprintsForProject(this.project.id).then(r => {
      //     if (r.status === 200) {
      //       this.sprints = r.data
      //     }
      //   })
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-table {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 15px 0;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}

.filter-clear {
  font-size: 12px;
  color: rgba(220, 53, 69, 0.5);
  cursor: pointer;

  &:hover {
    color: rgba(220, 53, 69, 0.8);
  }
}

.modal-filter {
  color: #1ab7ea;

  .v-select {
    .vs__selected {
      margin-top: 3px !important;
    }
  }
}
</style>

<style lang="scss">
.mr-custom .custom-switch .custom-control-label {
  padding-left: 3rem;
  line-height: 1.7rem;
}

.dropdown-notification {
  list-style-type: none;
}

.header-navbar .navbar-container .dropdown-menu-media {
  max-height: unset;
}
</style>