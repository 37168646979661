<template>
  <div class="tw-flex tw-justify-between tw-mb-2">
    <h3 class="tw-mb-0 tw-font-semibold tw-text-gray-700">
      {{ title
      }}<span class="tw-text-base tw-font-medium tw-text-gray-500">
        / {{ $store.state.osBoard.selectedProject.title }}</span
      >
    </h3>
    <div class="d-flex">
      <!--      <b-button-->
      <!--        v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
      <!--        class="mr-75"-->
      <!--        size="sm"-->
      <!--        variant="outline-primary"-->
      <!--        @click="tourStart"-->
      <!--      >-->
      <!--        Start Tour-->
      <!--      </b-button>-->
      <app-tour :steps="steps" />
      <HandlerFilter />
    </div>
  </div>
</template>

<script>
import HandlerFilter from '@/components/HandlerFilter.vue';
import AppTour from '@core/components/app-tour/AppTour.vue';
import Ripple from 'vue-ripple-directive';

export default {
  props: {
    title: {
      type: String,
    },
  },
  components: {
    AppTour,
    HandlerFilter,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      steps: [
        {
          target: '[data-v-step="1"]',
          header: {
            title: 'Add Task',
          },
          content: 'Button to create a task.',
        },
        {
          target: '#tour-card .icon-code',
          header: {
            title: 'View demo Source',
          },
          content:
            'If you ever find your self confused, you can click on this code icon to check the source of current demo.',
        },
        {
          target: '#tour-card .btn',
          header: {
            title: 'Trigger Tour',
          },
          content: 'You can click on this button to trigger the tour.',
        },
      ],
    };
  },
  methods: {
    // tour steps
    tourStart() {
      this.$tours.vuexyTour.start();
    },
  },
};
</script>

<style lang="scss" scoped></style>