<template>
  <div class="tw-hidden lg:tw-flex tw-ml-8">
    <b-link class="navbar-brand" to="/">
      <span class="brand-logo">
        <b-img :src="appLogoImage" alt="logo" class="tw-h-7" />
      </span>
      <!--      <h2 class="brand-text mb-0">Oscar <span style="color: #5e5873">Board</span></h2>-->
    </b-link>
  </div>
</template>

<script>
import {BImg, BLink} from 'bootstrap-vue';
import {$themeConfig} from '@themeConfig';

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const {appName, appLogoImage} = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
.navbar-brand {
  position: relative;

  .brand-text {
    position: absolute;
    top: 0;
    width: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    background-color: #fff;
    border-radius: 10px;
    padding-left: 0 !important;
  }

  &:hover {
    .brand-text {
      width: 100%;
      padding-left: 1.6rem !important;
    }
  }
}
</style>