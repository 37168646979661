<template>
  <div
    class="main-wrapper tw-pt-[50px] tw-pb-[90px] md:tw-pb-[60px] tw-px-5 md:tw-px-8"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="content-wrapper flex-grow-1" :class="contentWidth === 'boxed' ? 'container p-0' : null">
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body tw-h-full tw-overflow-hidden">
        <transition :name="routerTransition" mode="out-in" id="0" transition-path="">
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    AppBreadcrumb,
  },
  setup() {
    const {routerTransition, contentWidth} = useAppConfig()

    return {
      routerTransition,
      contentWidth,
    }
  },
}
</script>

<style></style>
