<template>
  <div class="tw-flex tw-py-2 tw-w-full tw-items-center tw-bg-white tw-z-20 tw-fixed">
    <app-navbar-horizontal-layout-brand />

    <!-- Nav Menu Toggler -->
    <ul class="tw-flex lg:tw-hidden tw-ml-6 tw-mb-0">
      <li class="tw-flex">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="20" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 tw-pl-4 tw-hidden lg:tw-flex">
      <horizontal-nav-menu-items :items="navMenuItems" />
    </div>

    <div class="tw-ml-auto tw-flex tw-items-center tw-gap-x-1">
      <!-- <DarkToggler class="d-none d-lg-block" />-->

      <div class="tw-w-[30px] tw-text-center">
        <button
          v-if="$store.state.osBoard.user.username === 'nikita.s'"
          class="tw-p-1 tw-text-gray-500 tw-border tw-border-gray-500 hover:tw-border-gray-400 hover:tw-bg-gray-100 tw-transition tw-rounded-md"
          @click="store.commit('other/changeCreateOther', true)"
        >
          <svg
            class="tw-w-4 tw-h-4"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 4.5v15m7.5-7.5h-15" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </div>

      <Fullscreen style="list-style-type: none" class="max-md:tw-hidden" />

      <!-- <Locale style="list-style-type: none"/>-->

<!--      <Currencies class="max-md:tw-hidden" />-->

<!--      <Wallet class="max-md:tw-hidden" />-->

      <!-- <NotificationDropdown /> -->

      <FilterDropdown />

<!--      <Notebook />-->

      <b-navbar-nav class="nav tw-items-center tw-mr-8 tw-ml-4">
        <b-nav-item-dropdown
          class="dropdown-user"
          no-caret
          right
          toggle-class="d-flex align-items-center dropdown-user-link tw-p-0 tw-hidden lg:tw-flex"
        >
          <template #button-content>
            <div class="tw-hidden md:tw-flex tw-flex-col tw-items-end tw-mr-2">
              <p class="tw-mb-0 tw-font-semibold tw-text-gray-700">
                {{ $store.state.osBoard.user.first_name }} {{ $store.state.osBoard.user.last_name }}
              </p>
              <span v-if="$store.state.osBoard.user['worker_type']" class="tw-text-gray-600 tw-text-xs tw-leading-3">{{
                $store.state.osBoard.user['worker_type'].toUpperCase()
              }}</span>
            </div>
            <b-avatar badge badge-variant="success" class="badge-minimal" size="30" variant="light-primary" />
          </template>

          <b-dropdown-item link-class="d-flex align-items-center" to="/profile">
            <feather-icon class="mr-50" icon="UserIcon" size="16" />
            <span>Profile</span>
          </b-dropdown-item>

          <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
          <!--          <feather-icon class="mr-50" icon="MailIcon" size="16" />-->
          <!--          <span>Inbox</span>-->
          <!--        </b-dropdown-item>-->

          <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
          <!--          <feather-icon class="mr-50" icon="CheckSquareIcon" size="16" />-->
          <!--          <span>Task</span>-->
          <!--        </b-dropdown-item>-->

          <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
          <!--          <feather-icon class="mr-50" icon="MessageSquareIcon" size="16" />-->
          <!--          <span>Chat</span>-->
          <!--        </b-dropdown-item>-->

          <!--        <b-dropdown-divider />-->

          <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
            <feather-icon class="mr-50" icon="LogOutIcon" size="16" />
            <span>Logout</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>
  </div>
</template>

<script>
import {BAvatar, BDropdownItem, BLink, BNavbarNav, BNavItemDropdown} from 'bootstrap-vue';
import Fullscreen from '@/components/Fullscreen';
import navMenuItems from '@/navigation/horizontal';
import HorizontalNavMenuItems from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue';
import Notebook from '@/components/Notebook.vue';
import FilterDropdown from '@/components/FilterDropdown.vue';
import Currencies from '@/components/Currencies.vue';
import Wallet from '@/components/Wallet.vue';
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue';
import store from '@/store';

export default {
  computed: {
    store() {
      return store;
    },
  },
  components: {
    AppNavbarHorizontalLayoutBrand,
    Wallet,
    Currencies,
    FilterDropdown,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    Notebook,

    // Navbar Components
    Fullscreen,
    HorizontalNavMenuItems,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      name: 'John Doe',
      role: 'Student',
      badge: 'danger',
      navMenuItems,
    };
  },
  mounted() {
    this.badge = localStorage.getItem('access_token') !== null ? 'success' : 'danger';
  },
  methods: {
    logout() {
      localStorage.removeItem('access_token');
      this.badge = 'primary';
      this.name = '';
      this.badge = 'danger';
      this.role = '';
      this.$router.push('/login');
    },
  },
};
</script>
