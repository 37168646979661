import axiosIns, {auth} from '@/libs/axios'

class FinanceService {
  getHistory() {
    return axiosIns.get('finance/history/', {
      headers: auth(),
    })
  }

  getFinanceSprint() {
    return axiosIns.get('finance/sprint/', {
      headers: auth(),
    })
  }
}

export default new FinanceService()
