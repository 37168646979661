<template>
  <b-nav-item-dropdown
    class="dropdown-notification"
    menu-class="dropdown-menu-media wallet-menu-media py-0"
    right
    no-caret
    toggle-class="p-0"
    @show="getUSDtoRUB"
  >
    <template #button-content>
      <li v-tippy="{content: 'Wallet'}" class="nav-item nav-item-custom">
        <div class="nav-link rounded-lg d-flex align-items-center text-body">
          <font-ai :icon="['fas', 'wallet']" class="font-medium-1" />
        </div>
      </li>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header border-bottom-0 py-50">
      <div class="dropdown-header px-2 d-flex">
        <div class="mr-auto d-flex align-items-end">
          <h4 class="notification-title mb-0">Wallet</h4>
          <span :style="{'margin-bottom': '3px'}" class="btn-link ml-50 cursor-pointer" @click="$router.push('/wallet')"
            >Details</span
          >
        </div>
        <div>
          <b-badge class="font-small-4" variant="light-primary">{{ total.rub.toLocaleString() }} ₽</b-badge>
          <b-badge class="font-small-4" variant="light-primary">{{ total.usd.toLocaleString() }} $</b-badge>
        </div>
      </div>
    </li>

    <!--    <h5 class="px-1 pt-75">History</h5>-->

    <div v-if="history.length > 0" class="bg-light">
      <DataTable :value="history" class="p-datatable mb-0" dataKey="id" responsiveLayout="scroll">
        <Column :body-style="{color: '#6e6b7b'}" body-class="pl-1" field="sprint" header="Sprint" header-class="pl-1">
          <template #body="slotProps">
            {{ `#${slotProps.data.sprint.name} (${slotProps.data.sprint.project.title})` }}
          </template>
        </Column>
        <Column :body-style="{color: '#6e6b7b'}" field="amount" header="Amount">
          <template #body="slotProps">
            <font-ai
              v-if="['$'].includes(slotProps.data.currency)"
              :icon="['fas', 'dollar-sign']"
              :style="{'max-width': '10px'}"
              class="font-small-3 mr-25"
            />
            <font-ai
              v-else-if="['₽'].includes(slotProps.data.currency)"
              :icon="['fas', 'ruble-sign']"
              :style="{'max-width': '10px'}"
              class="font-small-3 mr-25"
            />
            <font-ai
              v-else-if="['TRY'].includes(slotProps.data.code)"
              :icon="['fas', 'turkish-lira']"
              :style="{'max-width': '10px'}"
              class="font-small-3 mr-25"
            />
            <font-ai
              v-else-if="['EUR'].includes(slotProps.data.code)"
              :icon="['fas', 'euro-sign']"
              :style="{'max-width': '10px'}"
              class="font-small-3 mr-25"
            />
            <font-ai v-else :icon="['fas', 'coins']" :style="{'min-width': '15px'}" class="font-small-2 mr-25" />
            {{ slotProps.data.amount }} {{ slotProps.data.inRub ? ` (~ ${slotProps.data.inRub}р.)` : '' }}
          </template>
        </Column>
        <Column field="status" header="Status">
          <template #body="slotProps">
            <b-badge
              :variant="`light-${slotProps.data.status === 'planned' ? 'warning' : 'success'}`"
              class="font-small-2"
              >{{ slotProps.data.status.toUpperCase() }}
            </b-badge>
          </template>
        </Column>
        <Column field="date" header="Date">
          <template #body="slotProps">
            {{ !slotProps.data.date ? '' : formatDate(slotProps.data.date) }}
          </template>
        </Column>
      </DataTable>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {BBadge, BNavItemDropdown} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Column from 'primevue/column/Column'
import DataTable from 'primevue/datatable/DataTable'
import 'primevue/resources/themes/saga-blue/theme.css'
import moment from 'moment'
import FinanceService from '@/services/finance.service'
import axios from 'axios'

export default {
  components: {
    BBadge,
    DataTable,
    Column,
    BNavItemDropdown,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      history: [],
      currency: null,
      total: {
        rub: 0,
        usd: 0,
      },
    }
  },
  watch: {
    selectedCurrency() {
      this.getCurrencies()
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm')
    },
    getCurrencies() {
      FinanceService.getHistory().then(async r => {
        this.total.usd = 0
        this.total.rub = 0
        this.history = r.data.map(historyItem => {
          if (historyItem.currency === '$' && historyItem.status === 'planned')
            this.total.usd = this.total.usd + Number(historyItem.amount)
          if (historyItem.currency === '₽' && historyItem.status === 'planned')
            this.total.rub = this.total.rub + Number(historyItem.amount)
          return {
            ...historyItem,
            inRub: historyItem.currency === '$' ? Number(this.currency * historyItem.amount).toFixed(2) : null,
          }
        })
      })
    },
    getUSDtoRUB() {
      axios.get(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd/rub.min.json`).then(r => {
        this.currency = r.data['rub']
        this.getCurrencies()
      })
    },
    onUpdateCurrencies() {
      clearTimeout(this.timer)
      this.timer = null
      this.timer = setTimeout(() => {
        this.getCurrencies()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.dropdown-notification {
  list-style-type: none;
}

.header-navbar .navbar-container .dropdown-menu-media {
  overflow: hidden;
}

.wallet-menu-media {
  width: 46rem !important;

  @media (max-width: 768px) {
    width: 100% !important;
    border-radius: 0;
    position: fixed !important;
    top: 35px !important;
    left: 0 !important;
    transform: translate3d(0, 0, 0) !important;
  }
}
</style>