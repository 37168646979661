<template>
  <li
    v-if="$can('read', item['resource'])"
    v-tippy="{content: t(item['title'])}"
    :class="{'sidebar-group-active active': isActive}"
    class="nav-item"
    @click="$router.push({name: item['route'], params: item['params']})"
  >
    <b-link v-if="item.hasOwnProperty('params')" class="nav-link rounded-lg d-flex align-items-center">
      <font-ai :icon="item['icon']" class="font-medium-1" />
    </b-link>
    <b-link v-else class="nav-link rounded-lg d-flex align-items-center">
      <font-ai :icon="item['icon']" class="font-medium-1" />
    </b-link>
  </li>
</template>

<script>
import {BLink} from 'bootstrap-vue'
import {useUtils as useI18nUtils} from '@core/libs/i18n'
import {useUtils as useAclUtils} from '@core/libs/acl'
import useHorizontalNavMenuHeaderLink from './useHorizontalNavMenuHeaderLink'
import mixinHorizontalNavMenuHeaderLink from './mixinHorizontalNavMenuHeaderLink'

export default {
  components: {
    BLink,
  },
  mixins: [mixinHorizontalNavMenuHeaderLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {isActive, updateIsActive} = useHorizontalNavMenuHeaderLink(props.item)

    const {t} = useI18nUtils()
    const {canViewHorizontalNavMenuHeaderLink} = useAclUtils()

    return {
      isActive,
      updateIsActive,

      // ACL
      canViewHorizontalNavMenuHeaderLink,

      // i18n
      t,
    }
  },
}
</script>
