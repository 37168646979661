export default [
  {
    title: 'Projects',
    route: 'projects',
    icon: 'fa-solid fa-grip',
    action: 'read',
    resource: 'Projects',
    access: ['manager'],
  },
  {
    title: 'Tasks',
    route: 'tasks',
    icon: 'fa-solid fa-list-check',
    action: 'read',
    resource: 'Tasks',
    access: ['manager'],
  },
  {
    title: 'Planning',
    route: 'planning',
    icon: 'fa-arrows-turn-to-dots',
    action: 'read',
    resource: 'Planning',
    access: ['manager'],
  },
  {
    title: 'Board',
    route: 'board',
    icon: 'fa-solid fa-table-columns',
    action: 'read',
    resource: 'Board',
    access: ['manager', 'developer'],
  },
  // {
  //     header: 'Charts',
  //     icon: 'fa-solid fa-chart-line',
  //     access: ['manager'],
  //     children: [
  //         {
  //             title: 'Gantt Chart',
  //             route: 'gantt',
  //             icon: 'fa-solid fa-chart-gantt',
  //             access: ['manager'],
  //         },
  //         {
  //             title: 'Flow Chart',
  //             route: 'flow',
  //             icon: 'fa-solid fa-diagram-project',
  //             access: ['manager'],
  //         },
  //     ]
  // },
  {
    title: 'Knowledge Base',
    route: 'knowledge-base',
    icon: 'fa-solid fa-graduation-cap',
    action: 'read',
    resource: 'Knowledge-base',
    access: ['manager'],
  },
]
