<template>
  <div class="task-todo tw-px-6 tw-pb-6 tw-flex tw-flex-col tw-flex-grow overflow-hidden">
    <input
      v-if="showEditor"
      v-model="newItemText"
      class="tw-shadow-none tw-flex tw-h-11 tw-w-full tw-items-center tw-justify-center tw-rounded-lg tw-border tw-bg-white/0 tw-p-3 tw-text-sm tw-outline-none tw-border-gray-200"
      placeholder="Add text and press Enter"
      @keydown.enter="addItem"
    />
    <ul
      v-show="items.length > 0"
      class="tw-flex tw-items-center tw-mt-2 tw-gap-x-2 tw-justify-end tw-text-sm tw-font-semibold tw-text-gray-700"
    >
      <li class="tw-cursor-pointer hover:tw-text-sky-500" @click="changeStatusAll(true)">Select all</li>
      <li class="tw-cursor-pointer hover:tw-text-sky-500" @click="changeStatusAll(false)">Clear all</li>
      <li v-if="showEditor" class="tw-cursor-pointer hover:tw-text-sky-500" @click="deleteAllItems">Delete all</li>
    </ul>
    <ul
      v-show="items.length > 0"
      class="tw-flex tw-flex-col tw-gap-y-4 tw-mt-4 tw-flex-grow tw-h-full tw-overflow-auto board-scroll"
    >
      <li v-for="(item, index) in todoList" :key="index" class="tw-px-0 tw-flex tw-items-center tw-justify-between">
        <b-form-checkbox v-model="item.active" :value="true" @change="checkItem(item, index)">
          <span :class="{'text-muted line-through': item.active}" class="tw-text-base tw-font-bold tw-text-gray-700">
            {{ item.title }}
            <span v-if="item.date" :class="colorVariant(item.date)" class="ml-25">
              {{ item.date }}
            </span>
          </span>
        </b-form-checkbox>
        <svg
          v-if="showEditor"
          class="tw-w-5 tw-h-5 tw-cursor-pointer hover:tw-text-red-400 tw-transition"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          @click.stop="deleteItem(item.id)"
        >
          <path
            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </li>
    </ul>
  </div>
</template>

<script>
import {BFormCheckbox} from 'bootstrap-vue';
import {initial, last} from 'lodash';
import moment from 'moment';

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    showEditor: {
      type: Boolean,
      default: true,
    },
    todoList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newItemText: '',
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  mounted() {
    this.items = this.todoList ? this.todoList : [];
  },
  methods: {
    changeStatusAll(status) {
      this.items.forEach(item => {
        item.active = status;
      });
      this.$emit('update', this.items);
    },
    addItem() {
      const defaultData = {id: this.items.length, active: false};
      const abbreviatedDate = this.newItemText.search('(0[1-9]|[12][0-9]|3[01])\\.([0][1-9]|[1][012])$');
      const fullDate = this.newItemText.search(
        '(0[1-9]|[12][0-9]|3[01])\\.([0][1-9]|[1][012])\\.([12][0-9][0-9][0-9])'
      );
      if (abbreviatedDate !== -1) {
        this.items.push({
          ...defaultData,
          title: initial(this.newItemText.split(' ')).join(' '),
          date: last(this.newItemText.split(' ')) + '.' + moment().format('YYYY'),
        });
      } else if (fullDate !== -1) {
        this.items.push({
          ...defaultData,
          title: initial(this.newItemText.split(' ')).join(' '),
          date: last(this.newItemText.split(' ')),
        });
      } else {
        this.items.push({
          ...defaultData,
          title: this.newItemText,
          date: '',
        });
      }
      this.newItemText = '';
      this.$emit('update', this.items);
    },
    checkItem(item, index) {
      if (item.active) {
        const obj = this.items[index];
        let newArr = this.items;
        newArr.splice(index, 1);
        newArr.push(obj);
        this.items = newArr;
      }
      this.$emit('update', this.items);
    },
    deleteItem(id) {
      const index = this.items.findIndex(item => item.id === id);
      this.items.splice(index, 1);
      this.$emit('update', this.items);
    },
    deleteAllItems() {
      this.items = [];
      this.$emit('update', this.items);
      this.$emit('clearList');
    },
    colorVariant(date) {
      if (Number(moment(date, 'DD.MM.YYYY').format('x')) > moment.now()) return 'light-primary';
      if (Number(moment(date, 'DD.MM.YYYY').format('x')) < moment.now()) return 'light-danger';
    },
  },
};
</script>

<style lang="scss">
.task-todo {
  transition: background-color 0.3s, padding-left 0.3s, padding-right 0.3s ease;
  //background-color: #f5f8fa;
  padding-left: 1rem;
  padding-right: 1rem;

  .form-control {
    border: 1px solid #d8d6de;
  }

  .list-group-item {
    padding: 0.55rem 0.95rem;
    border-color: #ebebeb;

    svg {
      opacity: 0;
      transition: all 0.5s ease;
    }
  }

  .list-group-item:hover {
    svg {
      opacity: 1;
    }

    svg:hover {
      color: #dc3545ff !important;
    }
  }

  .custom-checkbox {
    padding-left: 2.2rem;
    height: 24px;
    display: flex;
    align-items: center;

    .custom-control-label::before {
      border-radius: 6px;
      width: 22px;
      height: 22px;
    }

    .custom-control-label::after {
      cursor: pointer;
      transform: rotate(0deg);
      width: 22px;
      height: 22px;
    }
  }
}
</style>