<template>
  <p class="tw-mb-0 tw-flex tw-justify-between tw-items-center tw-pb-8 md:tw-pb-0">
    <span class="mt-25 tw-hidden md:tw-flex">
      COPYRIGHT © {{ new Date().getFullYear() }}
      <a class="ml-25" href="https://oscar-studio.ru" target="_blank">Oscar Studio</a>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>

    <!-- Left Col -->
    <span class="tw-items-center tw-flex-grow-1 tw-gap-8 tw-pl-4 md:tw-hidden tw-flex tw-mx-auto">
      <horizontal-nav-menu-items :items="navMenuItems" is-footer />
    </span>

    <span class="md:tw-flex tw-hidden">
      <a href="http://drone.oscarbot.ru/board/frontend">
        <img alt="" src="http://drone.oscarbot.ru/api/badges/board/frontend/status.svg" />
      </a>
    </span>
  </p>
</template>

<script>
import HorizontalNavMenuItems from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'
import navMenuItems from '@/navigation/horizontal'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  data() {
    return {
      navMenuItems,
    }
  },
}
</script>
