<template>
  <li v-tippy="{content: 'Fullscreen'}" class="nav-item nav-item-custom">
    <b-link class="nav-link rounded-lg d-flex align-items-center text-body" @click="toggleFullscreen">
      <font-ai :icon="['fas', 'expand']" class="font-medium-1" />
    </b-link>
  </li>
</template>

<script>
import {BLink} from 'bootstrap-vue';

export default {
  components: {
    BLink,
  },
  methods: {
    toggleFullscreen() {
      this.$store.commit('appConfig/UPDATE_FULLSCREEN', !this.fullscreen);
    },
  },
  computed: {
    fullscreen() {
      return this.$store.state.appConfig.layout.fullscreen;
    },
  },
};
</script>
